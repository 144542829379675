.brand3d-project-gallery-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 960px;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 500ms;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.brand3d-project-gallery {
  background: white;
  overflow-y: scroll;
  height: 100%;
  max-height: 100vh;
  box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 1);

  h3 {
    padding: 1rem;
    margin: 0;
  }
}

.brand3d-gallery-grid {
  width: auto;
  position: relative;
  --autogrid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--autogrid-min-size), (1fr)));
  grid-gap: 1rem;
  max-width: 100%;
  margin: auto;
  padding: 1rem;
  height: auto; 
}

.brand3d-gallery-item {
  background: #f0f0f0;
  padding: 1rem;
  // box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.25);
  transition: all 200ms;
  cursor: pointer;
  
  &:hover {
    // box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 1);
    transform: scale(1.03);
  }
}

.brand3d-gallery-thumbnail {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 133%;
    transform: translate(-50%, -50%);
  }
}

.brand3d-close-button {
  position: absolute;
  top: 10px;
  right: 2rem;
  height: 2rem;
  width: 2rem;
  text-align: center;
  line-height: 1.8rem;
  font-size: 2rem;
  cursor: pointer;
  color: #303030;
  transition: all 250ms;

  &:hover {
    color: lighten(#303030, 20%);
    transform: scale(1.1);
  }
}

.brand3d-lightbox {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  backdrop-filter: blur(4px);
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 2vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: all 500ms;
  align-content: center;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
  
  img {
    display: block;
    margin: auto;
    transform: all 10ms;
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.95);
  }

  .brand3d-close-button {
    color: white;
  }

}

.brand3d-lightbox-previous,
.brand3d-lightbox-next {
  position: absolute;
  top: 50%;
  padding: 1rem;
  font-size: 3rem;
  transform: translateY(-50%);
  border-radius: 10rem;
  text-align: center;
  cursor: pointer;
  transition: all 250ms;
  color: #c0c0c0;
  text-shadow: 0 1px 4px black;

  &:hover {
    color: white;
  }
}

.brand3d-lightbox-previous {
  left: 2rem;
}

.brand3d-lightbox-next {
  right: 2rem;
}

.brand3d-lightbox-counter {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-shadow: 0 2px 4px black, 0 2px 4px black, 0 2px 4px black;
}

.brand3d-gallery-title,
.project-notes-title {
  font-weight: bold;
}