$devrioAccent: #417d75;

.brand3d-archtool-toolbar {
  position: fixed;
  top: 10px;
  left: 10px;
  background: $devrioAccent;
  border-radius: 50px;
  padding: 5px;
  color: white;
  overflow: hidden;
  transition: all 250ms;
}

$menuHeight: 44px;

.brand3d-archtool-control {
  border-radius: 40px;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 24px;
  line-height: $menuHeight;
  margin: 0;
  max-width: 50px;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  text-align: center;
  transition: all 250ms;
  transition: all 500ms;
  width: $menuHeight;

  &.visible {
    height: $menuHeight;
    pointer-events: all;
  }

  &.hidden {
    height: 0;
    pointer-events: none;
  }

  .icon-ruler {
    font-size: 28px;
  }

  .icon-images {
    font-size: 26px;
  }

  &.active {
    color: rgba(255, 255, 255, 0.65);
  }
}

.brand3d-modal-help {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.5);
  color: #303030;
  user-select: none;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms;

  &.visible {
    pointer-events: all;
    opacity: 1;
  }
}

.brand3d-modal-title,
.brand3d-modal-content {
  padding: 1rem;
}

.brand3d-modal-title {
  font-weight: bold;
  position: relative;
  border-bottom: 1px solid #c0c0c0;
  font-size: 1.4rem;

  .brand3d-close-button {
    right: 1rem;
    top: 1rem;
  }
}

.brand3d-help-hints {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%;
  
  th, td {
    border: 0;
    padding: 0.25rem;
  }
  
  th {
    font-size: 0.9rem;
    padding: 0.5rem;
    font-weight: normal;
    color: white;
    text-transform: uppercase;
    background-color: $devrioAccent;
  }

  td {
    &:first-of-type {
      text-transform: uppercase;
    }
    
    &:not(:first-of-type) {
      text-align: center;
      font-size: 1.4rem;
      color: #303030;
    }
  }
}

.unit-toggle {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 250ms;
  transform: translate(-50%, -50%);
  font-family: Courier, monospace;
  font-weight: bolder;
  

  &.metric {
    font-size: 1.8rem;
  }

  &.imperial {
    font-size: 1.4rem;
  }

  &.hidden {
    &.metric {
      transform: translate(-300%, -50%);
    }
    &.imperial {
      transform: translate(200%, -50%);
    }
  }
}