@media (min-aspect-ratio: 1/1) {
    .brand3d-lightbox {
        // background: red;

         img {
            // height: 100vh;
            max-height: 96vh;
            width: auto;
            max-width: 96vw;
         }
    }
  }

  @media (max-aspect-ratio: 1/1) {
    .brand3d-lightbox {
        // background: blue;

        img {
            max-width: 100%;
            height: auto;
        }
    }
  }