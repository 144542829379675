@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@import './gallery';
@import './viewer-controls';
@import './media-queries';

.brand3d-archtool-measurements-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(255, 0, 0, 0.1);
  // background: white;
  pointer-events: none;
  overflow: hidden;
}

.brand3d-archtool-p3d-iframe {
  border: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
}



.brand3d-project-notes-container {
  background: white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25); 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 32rem;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .brand3d-notes-close {
    right: 1rem;
  }

  .project-notes-content {
    flex: 1;
  }
}

.brand3d-project-notes{
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-notes-title,
.project-notes-section {
  padding: 0 1rem;
}

.project-notes-content {
  border: 1px solid #808080;
  padding: 1rem;
  border-radius: 0.5rem;
}

.brand3d-project-branding {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 20vw;
  max-width: 120px;

  img {
    display: block;
    width: 100%;
  }
}